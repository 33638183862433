import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { DocumentReference } from '@angular/fire/firestore';

// autogramm models
import { Account } from 'app/core/account/account.model';

// autogramm services
import { FirestoreService } from 'app/core/firebase/firestore.service';
import { AccountStatus } from './account-status.enum';
import { NavigationService } from '../navigation/navigation.service';
import { UserService } from '../user/user.service';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	private _account: ReplaySubject<Account> = new ReplaySubject<Account>(null);
	private _stripeCustomer: ReplaySubject<any> =
		new ReplaySubject<any>(1);
	private _hasAccount: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	private _type: string;
	private _accountRef: ReplaySubject<DocumentReference> =
		new ReplaySubject<DocumentReference>(null);
	private _stripeCustomerRef: ReplaySubject<DocumentReference> =
		new ReplaySubject<DocumentReference>();
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(
		private _firestoreService: FirestoreService,
		private _navigationService: NavigationService,
		private _userService: UserService
	) {
		this.init();
	}

	init() {
		this._userService.user$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((user) => {
				if (user.account) {
					this._hasAccount.next(true);
					this._accountRef.next(user.account);
					this._firestoreService
						.getDocumentChanges(user.account)
						.pipe(takeUntil(this._unsubscribeAll))
						.subscribe((account: Account) => {
							this._account.next(account);
							if (account.stripeCustomer) {
								this._stripeCustomerRef.next(account.stripeCustomer);
								account.stripeCustomer.get().then((stripeCustomer) => {
									const stripCust = stripeCustomer.data();
									this._stripeCustomer.next(stripCust);
								});
							}
						});
				}
			});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for user
	 *
	 * @param value
	 */
	set account(value: Account) {
		this._account.next(value);
	}

	set accountRef(ref: DocumentReference) {
		this._accountRef.next(ref);
	}

	set stripeCustomer(stripeCustomer) {
		this._stripeCustomer.next(stripeCustomer);
	}

	set stripeCustomerRef(ref: DocumentReference) {
		this._stripeCustomerRef.next(ref);
	}

	set hasAccount(value: boolean) {
		this._hasAccount.next(value);
	}

	get account$(): Observable<Account> {
		return this._account.asObservable();
	}

	get hasAccount$(): Observable<boolean> {
		return this._hasAccount.asObservable();
	}

	get type(): string {
		return this._type;
	}

	get stripeCustomer$(): Observable<any> {
		return this._stripeCustomer.asObservable();
	}

	get accountRef$(): Observable<DocumentReference> {
		return this._accountRef.asObservable();
	}

	get stripeCustomerRef$(): Observable<DocumentReference> {
		return this._stripeCustomerRef.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// ----------------------------------------------------------------------------------------------------
	/**
	 * Update the account
	 *
	 * @param account
	 */
	update(account: Partial<Account>): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			// TODO: refactor
			this._account
				.asObservable()
				.pipe(first())
				.subscribe((currAcc) => {
					this._account.next({ ...currAcc, ...account });

					this._accountRef.pipe(first()).subscribe((accountRef) => {
						this._firestoreService
							.updateDocument(accountRef, account)
							.then(() => {
								resolve();
							})
							.catch((err) => {
								reject(err);
							});
					});
				});
		});
	}

	reset(): Promise<void> {
		return new Promise<void>((resolve) => {
			this._account.next(null);
			this._hasAccount.next(false);
			this._accountRef.next(null);
			this._stripeCustomer.next(null);
			this._unsubscribeAll.next();
			this._unsubscribeAll.complete();

			resolve();
		});
	}

	setAccountState(state: any): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this._account
				.asObservable()
				.pipe(first())
				.subscribe((currAcc) => {
					currAcc.status = state;
					this.account = currAcc;
					if (state == AccountStatus.active)
						this._navigationService.toggleDisabledMenu(false);
					else this._navigationService.toggleDisabledMenu(true);

					this.update(currAcc)
						.then(() => {
							resolve();
						})
						.catch((err) => {
							reject(err);
						});
				});
		});
	}
}
