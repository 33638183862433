import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import firebase from 'firebase/app';
import { DocumentReference } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';

// Fuse
import { FuseConfigService } from '@fuse/services/config';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import {
	FuseNavigationService,
	FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';

// autogramm models
import { User } from 'app/core/user/user.model';
import { UserRoles } from 'app/core/user/user-roles.enum';

// autogramm services
import { FirestoreService } from 'app/core/firebase/firestore.service';

// translation
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	// init private variables
	private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
	private _userRef: ReplaySubject<DocumentReference> =
		new ReplaySubject<DocumentReference>();
	private _userID: string;
	private _signatureURL: ReplaySubject<string> = new ReplaySubject<string>(1);
	private _accountRef: DocumentReference;
	private _language: string;
	private _email: string;
	private _roles: ReplaySubject<UserRoles[]> = new ReplaySubject<UserRoles[]>(1);

	/**
	 * Constructor
	 */
	constructor(
		private _firestoreService: FirestoreService,
		private _translocoService: TranslocoService,
		private _fuseConfigService: FuseConfigService,
		private _fuseNavigationService: FuseNavigationService
	) {}

	// ---------------------------------------------------------------------------
	// @ Accessors
	// ---------------------------------------------------------------------------

	/**
	 * Setter & getter for user
	 *
	 * @param value
	 */
	set user(value: User) {
		// Store the value
		this._user.next(value);
		this._roles.next(value.roles);
		this._userID = value.uid;
		this._signatureURL.next(value.signatureURL);
		this._accountRef = value.account;
		this._language = value.language ? value.language : navigator.language;
		this._email = value.email;

		// set language an scheme selected by user
		this._translocoService.setActiveLang(value.language ? value.language : navigator.language);
		this._fuseConfigService.config = { scheme: value.scheme };
	}

	set userRef(ref: DocumentReference) {
		this._userRef.next(ref);
	}

	get user$(): Observable<User> {
		return this._user.asObservable();
	}

	get userRef$(): Observable<DocumentReference> {
		return this._userRef.asObservable();
	}

	get userID(): string {
		return this._userID;
	}

	get account(): firebase.firestore.DocumentReference {
		return this._accountRef;
	}

	get language(): string {
		return this._language;
	}

	get signatureURL$(): Observable<string> {
		return this._signatureURL.asObservable();
	}

	get email(): string {
		return this._email;
	}

	get roles$(): Observable<UserRoles[]> {
		return this._roles.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------
	/**
	 * Update the user
	 *
	 * @param user
	 */
	update(user: Partial<User>): Promise<void> {
		return this._firestoreService.updateDocument('users', user, this._userID);
	}

	/**
	 * Update user data based on firebase auth
	 */
	updateAuthUser(user: firebase.User): Promise<void> {
		let userData: Partial<User> = {
			emailVerified: user.emailVerified,
			email: user.email,
		};

		return this._firestoreService.updateDocument('users', userData, user.uid);
	}

	updateNavigation(): void {
		const navComponent =
			this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
				'mainNavigation'
			);
		if (!navComponent) {
			return null;
		}
		this.translateNavigationItemsRecursive(navComponent.navigation, navComponent);
	}
	
	private translateNavigationItemsRecursive(
		navigationItems: FuseNavigationItem[],
		navComponent: FuseVerticalNavigationComponent
	) {
		navigationItems.forEach((navigationItem) => {
			const item = this._fuseNavigationService.getItem(
				navigationItem.id,
				navComponent.navigation
			);
			if (item) {
				this._translocoService
					.selectTranslateObject('sidebar.' + item.id.toLowerCase())
					.pipe(take(1))
					.subscribe((translation) => {
						if (typeof translation == 'string') item.title = translation;
						else {
							item.title = translation.title;
							item.subtitle = translation.subtitle;
						}
						navComponent.refresh();
					});
			}
			if (item.children && item.children.length > 0) {
				this.translateNavigationItemsRecursive(item.children, navComponent);
			}
		});
	}
}
