import { Injectable } from '@angular/core';

//Firebase
import {
	AngularFirestore,
	AngularFirestoreCollection,
	AngularFirestoreDocument,
	DocumentReference,
	Query,
} from '@angular/fire/firestore';

//autogramm.io - models
import { Activity } from 'app/core/activity/activity.model';
import { FirestoreFilter } from 'app/core/firebase/FirestoreFilter';
import { firestore } from 'firebase';
import { take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class FirestoreService {
	constructor(private _afs: AngularFirestore) {}

	updateDocument(
		pRef: string | DocumentReference,
		pData: Object,
		pID?: string
	): Promise<void> {
		pData['editedAt'] = new Date();

		if (typeof pRef == 'string') {
			return this._afs.collection(pRef).doc(pID).update(pData);
		} else return pRef.update(pData);
	}

	createDocument(pCollection: string, pData: Object, pIdField?: string) {
		pData['createdAt'] = new Date();

		try {
			return this._afs.collection(pCollection).add(pData);
		} catch (e) {
			console.log(e);
		}
	}

	createMultipleDocuments(
		documents: Array<{ reference: DocumentReference; data: any }>
	) {
		const batch = this._afs.firestore.batch();

		for (let i = 0; i < documents.length; i++) {
			batch.set(documents[i].reference, documents[i].data);
		}

		return batch.commit();
	}

	updateMultipleDocuments(
		documents: Array<{ reference: DocumentReference; data: any }>
	) {
		const batch = this._afs.firestore.batch();

		for (let i = 0; i < documents.length; i++) {
			batch.update(documents[i].reference, documents[i].data);
		}

		return batch.commit();
	}

	createChildDocument(
		pParentCollection: string,
		pChildCollection,
		pParentID,
		pData: Object,
		pChildID?: string
	) {
		var reference = this._afs
			.collection(pParentCollection)
			.doc(pParentID)
			.collection(pChildCollection);

		if (pChildID) return reference.doc(pChildID).set(pData);
		else return reference.add(pData);
	}

	setDocument(
		pCollection: string | string[],
		pData: Object,
		pID
	): Promise<DocumentReference> {
		let reference: DocumentReference;

		if (typeof pCollection == 'string')
			reference = this._afs.collection(pCollection).doc(pID).ref;
		else {
			reference = this._afs.doc(`${pCollection.join('/')}/${pID}`).ref;
		}

		return new Promise(function (resolve, reject) {
			reference
				.set(pData)
				.then(() => {
					resolve(reference);
				})
				.catch((err) => reject(err));
		});
	}

	getReference(pCollection: string, pID: string): DocumentReference {
		return this._afs.collection(pCollection).doc(pID).ref;
	}

	getDocument(pPath) {
		return this._afs.doc(pPath).get();
	}

	getDocumentChanges(pPath: string | DocumentReference) {
		if (typeof pPath == 'string')
			return this._afs.doc(pPath).valueChanges({ idField: 'id' });
		else return this._afs.doc(pPath).valueChanges({ idField: 'id' });
	}

	getCollectionChanges(pPath, pOptions?, pOrder?, pOrderDirection?) {
		if (pOrder) {
			if (!pOrderDirection) pOrderDirection = 'desc';

			return this._afs
				.collection(pPath, (ref) => ref.orderBy(pOrder, pOrderDirection))
				.valueChanges(pOptions);
		}

		return this._afs.collection(pPath).valueChanges(pOptions);
	}

	getQueryCollection(
		pPath: string,
		pFilter: FirestoreFilter
	): AngularFirestoreCollection {
		return this._afs.collection(pPath, (ref) =>
			ref.where(pFilter.column, pFilter.operator, pFilter.value)
		);
	}

	getCollectionQueryResults(pPath: string, pFilters: Array<FirestoreFilter>) {
		var collectionRef = this._afs.collection(pPath).ref;
		var query: Query;

		for (let i = 0; i < pFilters.length; i++) {
			if (query)
				query = query.where(
					pFilters[i].column,
					pFilters[i].operator,
					pFilters[i].value
				);
			else
				query = collectionRef.where(
					pFilters[i].column,
					pFilters[i].operator,
					pFilters[i].value
				);
		}

		return query;
	}

	getCollectionQueryChangesOLD(pPath: string, pFilters: FirestoreFilter) {
		return this._afs
			.collection(pPath, (ref) =>
				ref.where(pFilters.column, pFilters.operator, pFilters.value)
			)
			.valueChanges({ idField: 'id' });
	}

	getCollectionQueryChanges(
		pPath: string,
		pFilters: FirestoreFilter,
		pOrder?,
		pOrderDirection?: 'asc' | 'desc'
	) {
		return this._afs
			.collection(pPath, (ref) => {
				let query: firebase.firestore.Query = ref;
				query = query.where(pFilters.column, pFilters.operator, pFilters.value);

				if (pOrder && pOrderDirection) {
					query = query.orderBy(pOrder, pOrderDirection);
				}

				return query;
			})
			.valueChanges({ idField: 'id' });
	}

	/**
	 * Get documents ordered and paged
	 * Used for documents in file manager
	 */

	getCollectionQueryChangesPagination(
		pPath: string,
		pFilters: FirestoreFilter,
		pLimit: number,
		pStartAfter?: any,
		pStartAt?: any,
		pEndBefore?: any,
		pOrder?: string,
		pOrderDirection?,
		pSearchVal?
	) {
		return this._afs
			.collection(pPath, (ref) => {
				let query:
					| firebase.firestore.CollectionReference
					| firebase.firestore.Query = ref;
				query = query.where(pFilters.column, pFilters.operator, pFilters.value);
				if (pSearchVal) {
					query = query
						.where(pOrder, '>=', pSearchVal)
						.where(pOrder, '<', pSearchVal + '\uf8ff');
				} // uf8ff is last unicode character of the private use area in unicode,
				// thats why the query matches all values that match the searchVal
				// https://firebase.google.com/docs/database/admin/retrieve-data#node.js_18
				query = query.limit(pLimit);
				if (pOrder) {
					query = query.orderBy(pOrder, pOrderDirection);
				}
				if (pStartAfter) {
					query = query.startAfter(pStartAfter);
				}
				if (pStartAt) {
					query = query.startAt(pStartAt);
				}
				if (pEndBefore) {
					query = query.endBefore(pEndBefore);
				}
				return query;
			})
			.snapshotChanges();
		// use snapshotchanges as valuechanges can not handle the limitation of datasets
	}

	getDocumentReference(pPath: string): DocumentReference {
		return this._afs.doc(pPath).ref;
	}

	deleteDocument(collection: string, docID: string) {
		return this._afs.collection(collection).doc(docID).delete();
	}

	/**
	 * Helper Functions
	 */

	getTimestamp(pDate?: Date) {
		if (!pDate) pDate = new Date();

		return pDate;
	}

	getNewID(): string {
		return this._afs.createId();
	}

	deleteSubcollection(collection: string, subcollection: string, docID: string) {
		const collectionRef = this._afs.firestore
			.collection(collection)
			.doc(docID)
			.collection(subcollection);

		return new Promise((resolve, reject) => {
			this.deleteQueryBatch(collectionRef, resolve).catch(reject);
		});
	}

	async deleteQueryBatch(
		query: firestore.CollectionReference<firestore.DocumentData>,
		resolve
	) {
		const snapshot = await query.get();

		if (snapshot.size === 0) {
			// When there are no documents, we are done
			resolve();
			return;
		}

		// Delete documents in a batch
		const batch = this._afs.firestore.batch();
		snapshot.docs.forEach((doc) => {
			batch.delete(doc.ref);
		});
		await batch.commit();
	}
}
