import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

//Firebase
import { AngularFireAuth } from '@angular/fire/auth';
import { DocumentReference } from '@angular/fire/firestore';
import firebase from 'firebase/app';

//autogramm services
import { FirestoreService } from 'app/core/firebase/firestore.service';
import { UserService } from 'app/core/user/user.service';
import { AccountService } from 'app/core/account/account.service';

//autogramm - models
import { User } from 'app/core/user/user.model';
import { Account } from 'app/core/account/account.model';

//autogramm enums
import { UserRoles } from 'app/core/user/user-roles.enum';
import { AccountStatus } from 'app/core/account/account-status.enum';

// helpers
import { v4 as uuidv4 } from 'uuid';
import { UserBackendService } from '../backend/user-backend.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthService {
	private _authenticated: boolean = false;
	private _userInitialized: boolean = false;
	private _accountInitialized: boolean = false;
	private _stripeCustomerInitialized: boolean = false;
	private _auth: firebase.User;
	private _accountRef: DocumentReference;
	private _emailVerified: boolean;
	private _accountStatus: AccountStatus;
	private _accountRefSubject: ReplaySubject<DocumentReference> =
		new ReplaySubject<DocumentReference>(1);
	_unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(
		private _httpClient: HttpClient,
		private _userService: UserService,
		private _accountService: AccountService,
		private _afAuth: AngularFireAuth,
		private _firestoreService: FirestoreService,
		private _userBackendService: UserBackendService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute
	) {}

	initAuthData(): Promise<void> {
		return new Promise<void>((resolve) => {
			this._afAuth.authState
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe((authState) => {
					if (authState) {
						this._authenticated = true;
						this._auth = authState;
						this._emailVerified = authState.emailVerified;

						this._firestoreService
							.getDocumentChanges(`users/${authState.uid}`)
							.pipe(first())
							.subscribe((user: User) => {
								this._userService.user = user;
								this._initUserData(user.id);
								resolve();
							});
					}
				});
		});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	set accountRef(reference: DocumentReference) {
		this._accountRefSubject.next(reference);
	}

	get emailVerified(): boolean {
		return this._emailVerified;
	}

	get accountRef(): DocumentReference {
		return this._accountRef;
	}

	get accountRef$(): Observable<DocumentReference> {
		return this._accountRefSubject.asObservable();
	}

	get accountRefPromise(): Promise<DocumentReference> {
		return;
	}

	get accountStatus(): AccountStatus {
		return this._accountStatus;
	}

	get hasAccount(): boolean {
		if (this._accountRef) return true;
		else return false;
	}

	get authState(): firebase.User {
		return this._auth;
	}
	// ---------------------------------------------------------------------------
	// @ Public methods
	// ---------------------------------------------------------------------------

	/**
	 * Forgot password
	 *
	 * @param email
	 */
	forgotPassword(email: string): Promise<void> {
		let url = `https://${window.location.hostname}`;
		if (window.location.hostname == 'localhost') url = 'http://localhost:4200';

		return this._afAuth.sendPasswordResetEmail(email, {
			url: `${url}`,
		});
	}

	/**
	 * Reset password
	 *
	 * @param password
	 */
	resetPassword(password: string): Observable<any> {
		return this._httpClient.post('api/auth/reset-password', password);
	}

	//TODO: Refactor this shit
	/**
	 * Sign in
	 *
	 * @param credentials
	 */
	signIn(credentials: { email: string; password: string }): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			// Throw error, if the user is already logged in
			if (this._authenticated) {
				reject(new Error('user already signed in'));
			}

			this._afAuth
				.signInWithEmailAndPassword(credentials.email, credentials.password)
				.then((credentials) => {
					// Set the authenticated flag to true
					this._authenticated = true;

					this._initUserData(credentials.user.uid)
						.then(() => {
							this._userInitialized = true;
							// Set the redirect url.
							// The '/signed-in-redirect' is a dummy url to catch the request and redirect the user
							// to the correct page after a successful sign in. This way, that url can be set via
							// routing file and we don't have to touch here.
							const redirectURL =
								this._activatedRoute.snapshot.queryParamMap.get('redirectURL') ||
								'/signed-in-redirect';

							// Navigate to the redirect url
							this._router
								.navigateByUrl(redirectURL)
								.then(() => {
									resolve();
								})
								.catch((error) => {
									reject(error);
								});
						})
						.catch((error) => {
							reject(error);
						});
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	/**
	 * Sign in using the access token
	 */
	signInUsingToken(): Promise<{
		authenticated: boolean;
		verified?: boolean;
		hasAccount?: boolean;
	}> {
		return new Promise((resolve) => {
			this._afAuth.authState.pipe(first()).subscribe((user) => {
				if (user) {
					this._authenticated = true;
					this._initUserData(user.uid).then(() => {
						this._userInitialized = true;
						resolve({ authenticated: true, verified: user.emailVerified });
					});
				} else resolve({ authenticated: false });
			});

			return;
		});
	}

	/**
	 * Sign out
	 */
	signOut(): Promise<void> {
		return new Promise<void>((resolve) => {
			this._unsubscribeAll.next();
			this._unsubscribeAll.complete();

			// Set the authenticated flag to false
			this._authenticated = false;

			this._accountService.reset().then(() => {
				this._accountRef = null;
				this._accountRefSubject.next(null);

				// Remove the access token from the local storage
				localStorage.removeItem('access_token');
				this._userInitialized = false;
				this._accountInitialized = false;
				this._stripeCustomerInitialized = false;
				this._afAuth.signOut().then(() => {
					resolve();
				});
			});
		});
	}

	/**
	 * Sign up
	 *
	 * @param user
	 */
	signUp(user: {
		firstName: string;
		lastName: string;
		email: string;
		password: string;
		company: string;
	}): Promise<any> {
		let account: Account = {
			companyName: user.company,
			id: uuidv4(),
			status: AccountStatus.planSelected,
			type: 'personal',
			address: '',
			postalCode: '',
			city: '',
			country: 'de',
			email: '',
			credits: 10,
		};

		return new Promise<void>((resolve, reject) => {
			this._afAuth
				.createUserWithEmailAndPassword(user.email, user.password)
				.then((userCredential) => {
					this._auth = userCredential.user;
					this._authenticated = true;
					// create account async and update user
					this._firestoreService
						.setDocument('accounts', account, account.id)
						.then((accountReference) => {
							if (accountReference) {
								let userData: Partial<User> = {
									uid: userCredential.user.uid,
									firstName: user.firstName,
									lastName: user.lastName,
									email: user.email,
									account: accountReference,
									language: navigator.language.substr(0, 2),
									emailVerified: false,
									roles: [UserRoles.account_admin],
									scheme: 'auto',
								};

								this._firestoreService
									.setDocument('users', userData, userCredential.user.uid)
									.then((userReference) => {
										this._initUserData(userCredential.user.uid)
											.then(() => {
												this._userBackendService.linkAccount(
													accountReference.id,
													userCredential.user.uid,
													'true'
												);

												this.sendVerificationMail()
													.then(() => {
														resolve();
													})
													.catch((error) => {
														reject(error);
													});
											})
											.catch((error) => {
												reject(error);
											});
									});
							} else reject(new Error('missing account information during sign up'));
						})
						.catch((error) => {
							reject(error);
						});
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	/**
	 * Unlock session
	 *
	 * @param credentials
	 */
	unlockSession(credentials: {
		email: string;
		password: string;
	}): Observable<any> {
		return this._httpClient.post('api/auth/unlock-session', credentials);
	}

	reloadUser(): Promise<void> {
		return new Promise<void>((resolve) => {
			this._afAuth.currentUser.then((user) => {
				user.getIdTokenResult(true).then(() => {
					user.reload().then(() => {
						this._emailVerified = user.emailVerified;
						this.initAuthData().then(() => {
							resolve();
						});
					});
				});
			});
		});
	}

	/**
	 * Change a users password
	 */
	changePassword(pPasswords): Promise<any> {
		var credential = firebase.auth.EmailAuthProvider.credential(
			this._auth.email,
			pPasswords.currentPassword
		);

		return new Promise((resolve, reject) => {
			this._auth
				.reauthenticateWithCredential(credential)
				.then((usrCred) => {
					resolve(this._auth.updatePassword(pPasswords.password));
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/**
	 * Send verification email
	 * @returns Promise<void>
	 */
	sendVerificationMail(): Promise<void> {
		return this._auth.sendEmailVerification();
	}

	// ---------------------------------------------------------------------------
	// @ Private methods
	// ---------------------------------------------------------------------------

	private _initUserData(userID: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this._unsubscribeAll = new Subject<any>();

			if (!this._userInitialized) {
				// Load user data
				this._firestoreService
					.getDocumentChanges(`users/${userID}`)
					.pipe(first())
					.subscribe((user: User) => {
						this._userService.user = user;
						this._userService.userRef = this._firestoreService.getDocumentReference(
							`users/${userID}`
						);
						// setup account
						if (user.account) {
							this._accountService.init();
							resolve();
						} else {
							this._accountService.hasAccount = false;
							resolve();
							return;
						}
					});
			} else resolve();
		});
	}
}
