<!-- Shortcuts toggle -->
<button mat-icon-button (click)="openPanel()" #quickhelpOrigin>
	<mat-icon [svgIcon]="'heroicons_outline:question-mark-circle'"></mat-icon>
</button>

<!-- Shortcuts panel -->
<ng-template #quickhelpPanel>
	<div
		class="
			fixed
			inset-0
			sm:static sm:inset-auto
			flex flex-col
			sm:min-w-80 sm:w-100 sm:rounded-2xl
			overflow-hidden
			shadow-lg
		"
	>
		<!-- Header -->
		<div
			class="
				flex flex-shrink-0
				items-center
				py-4
				pr-4
				pl-6
				bg-primary
				text-on-primary
			"
		>
			<div>
				<button mat-icon-button (click)="closePanel()">
					<mat-icon
						class="icon-size-5 text-current"
						[svgIcon]="'heroicons_solid:x'"
					></mat-icon>
				</button>
			</div>
			<div class="flex w-full text-lg font-medium leading-10">Guide</div>
			<div class="flex w-full justify-end">
				<button mat-icon-button (click)="openGuide()">
					<mat-icon
						class="icon-size-5 text-current"
						[svgIcon]="'heroicons_solid:external-link'"
					></mat-icon>
				</button>
			</div>
		</div>
		<div
			class="
				relative
				flex flex-col flex-auto
				sm:max-h-120
				-mb-px
				overflow-y-auto
				bg-card
			"
		>
			<!-- No guide -->
			<ng-container *ngIf="!guide">
				<div
					class="
						flex flex-col flex-auto
						items-center
						justify-center
						sm:justify-start
						py-12
						px-8
					"
				>
					<div
						class="
							flex flex-0
							items-center
							justify-center
							w-14
							h-14
							rounded-full
							bg-primary-100
						"
					>
						<mat-icon
							class="text-primary-500-700"
							[svgIcon]="'heroicons_outline:question-mark-circle'"
						></mat-icon>
					</div>
					<ng-container *transloco="let t; read: 'quickhelp'">
						<div class="mt-5 text-2xl font-semibold tracking-tight">
							{{ t("noguide") }}
						</div>
						<div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
							{{ t("noguide_info") }}
						</div>
					</ng-container>
				</div>
			</ng-container>
			<ng-container *ngIf="guide">
				<div class="flex flex-col flex-auto sm:justify-start py-3 px-4">
					<div class="text-2xl font-extrabold tracking-tight leading-tight prose">
						{{ guide.title }}
					</div>
					<div
						class="mt-8 ml-2 sm:mt-12 max-w-none prose prose-sm"
						[innerHTML]="guide.content"
					></div>
				</div>
			</ng-container>
		</div>
	</div>
</ng-template>
