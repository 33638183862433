import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/core/user/user.model';
import { UserService } from 'app/core/user/user.service';

import { Scheme } from 'app/core/config/app.config';
import { FuseConfigService } from '@fuse/services/config';
import { TranslocoService } from '@ngneat/transloco';

//autogramm.io - services
import { AuthService } from 'app/core/auth/auth.service';

// autogramm enums
import { UserRoles } from 'app/core/user/user-roles.enum';

@Component({
	selector: 'user-menu',
	templateUrl: './user-menu.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'userMenu',
})
export class UserMenuComponent implements OnInit, OnDestroy {
	static ngAcceptInputType_showAvatar: BooleanInput;

	scheme: 'dark' | 'light';

	@Input() showAvatar: boolean = true;
	user: User;

	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _isAdmin: boolean = false;

	/**
	 * Constructor
	 */
	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private _router: Router,
		private _userService: UserService,
		private _authService: AuthService,
		private _translocoService: TranslocoService
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to user changes
		this._userService.user$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((user: User) => {
				this.user = user;

				if (user.roles && user.roles.indexOf(UserRoles['admin']) != -1) this._isAdmin = true;
				this._userService.updateNavigation();
				// Mark for check
				this._changeDetectorRef.markForCheck();
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Update the user status
	 *
	 * @param status
	 */
	updateUserStatus(status: string): void {
		// Return if user is not available
		if (!this.user) {
			return;
		}

		// Update the user
		this._userService.update({
			...this.user,
			status,
		});
	}

	/**
	 * Sign out
	 */
	signOut(): Promise<void> {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();

		return this._authService.signOut().then(() => {
			this._router.navigate(['/sign-out']);
		});
	}

	/**
	 * Set the scheme on the config
	 *
	 * @param scheme
	 */
	setScheme(scheme: Scheme): void {
		this._userService.update({ scheme: scheme });
	}

	/**
	 * set language
	 *
	 * @param language
	 */
	setLanguage(language: string): void {
		this._translocoService.setActiveLang(language);
		this._userService.update({ language: language });
		this._userService.updateNavigation();
	}

	switchToAdmin(): void {
		this._router.navigate(['/admin']);
	}

	/**
	 * variables
	 */

	get isAdmin(): boolean {
		return this._isAdmin;
	}
}
