import { FuseNavigationItem } from '@fuse/components/navigation';

export const mainNavigation: FuseNavigationItem[] = [
	{
		id: 'dashboards',
		title: 'Dashboards',
		subtitle: '',
		type: 'group',
		icon: 'heroicons_outline:collection',
		children: [
			{
				id: 'dashboards.dashboard',
				title: 'Dashboard',
				type: 'basic',
				icon: 'heroicons_outline:chart-pie',
				link: '/dashboard',
			},
		],
	},
	{
		id: 'documents',
		title: 'Documents',
		subtitle: 'Create and manage your documents',
		type: 'group',
		icon: 'heroicons_outline:collection',
		children: [
			{
				id: 'documents.create',
				title: 'Create Sign Request',
				type: 'basic',
				icon: 'heroicons_outline:pencil-alt',
				link: '/sign-request/create',
			},
			{
				id: 'documents.manage',
				title: 'File Manager',
				type: 'basic',
				icon: 'heroicons_outline:collection',
				link: '/file-manager',
			},
		],
	},
	{
		id: 'templates',
		title: 'Templates',
		subtitle: 'Create and manage your templates',
		type: 'group',
		icon: 'feather:tool',
		children: [
			{
				id: 'templates.create',
				title: 'Create template',
				type: 'basic',
				link: 'templates/create',
				icon: 'heroicons_outline:document-add',
			},
			{
				id: 'templates.manage',
				title: 'Template Manager',
				type: 'basic',
				link: 'templates/template-manager',
				icon: 'heroicons_outline:template',
			},
		],
	},
	// {
	// 	id: 'helpcenter',
	// 	title: 'Help Center',
	// 	subtitle: 'Check out our guides',
	// 	type: 'group',
	// 	icon: 'heroicons_outline:support',
	// 	children: [
	// 		{
	// 			id: 'helpcenter.home',
	// 			title: 'Home',
	// 			type: 'basic',
	// 			icon: 'heroicons_outline:support',
	// 			link: '/help-center',
	// 		},
	// 		{
	// 			id: 'helpcenter.guides',
	// 			title: 'Guides',
	// 			type: 'basic',
	// 			icon: 'heroicons_outline:document-search',
	// 			link: '/help-center/guides',
	// 		},
	// 		{
	// 			id: 'helpcenter.changelog',
	// 			title: 'Changelog',
	// 			type: 'basic',
	// 			icon: 'heroicons_outline:speakerphone',
	// 			link: '/help-center/changelog',
	// 		},
	// 	],
	// },
];

export const adminNavigation: FuseNavigationItem[] = [
	{
		id: 'administration',
		title: 'Admin',
		subtitle: 'Administration for autogramm',
		type: 'group',
		icon: 'heroicons_outline:collection',
		children: [
			{
				id: 'admin.manage',
				title: 'Account Manager',
				type: 'basic',
				icon: 'heroicons_outline:pencil-alt',
				link: '/admin/accounts',
			},
			{
				id: 'admin.guides',
				title: 'Guides Manager',
				type: 'basic',
				icon: 'heroicons_outline:document-search',
				link: '/admin/guides',
			},
		],
	},
];
