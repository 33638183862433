import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FeedbackComponent } from 'app/layout/common/feedback/feedback.component';

@NgModule({
	declarations: [FeedbackComponent],
	imports: [CommonModule, MatButtonModule, MatIconModule],
	exports: [FeedbackComponent],
})
export class FeedbackModule {}
