import { Injectable } from '@angular/core';
import {
	FuseNavigationItem,
	FuseNavigationService,
	FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';

//autogramm.io - data
import {
	mainNavigation,
	adminNavigation,
} from 'app/core/navigation/navigationStructure';
import { Observable, of, ReplaySubject } from 'rxjs';

@Injectable()
export class NavigationService {
	private readonly _mainNavigation: FuseNavigationItem[] = mainNavigation;
	private readonly _adminNavigation: FuseNavigationItem[] = adminNavigation;
	private _menuStructure: ReplaySubject<any> = new ReplaySubject<any>(1);

	constructor(private _fuseNavigationService: FuseNavigationService) {
		this._menuStructure.next({ main: this._mainNavigation });
	}

	// PUBLIC FUNCTION

	// VARIABLES
	get menuStructure$(): Observable<any> {
		return this._menuStructure.asObservable();
	}

	toggleDisabledMenu(isDisabled: boolean): void {
		const navComponent =
			this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
				'mainNavigation'
			);

		if (!navComponent) {
			return null;
		}

		const navigation = navComponent.navigation;
		for (const element of navigation){
			for (const child of element.children){
				this._fuseNavigationService.getItem(child.id, navigation).disabled = isDisabled;
			}
		}

		navComponent.refresh();
	}
}
