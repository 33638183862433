import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { UserMenuComponent } from 'app/layout/common/user-menu/user-menu.component';
import { SharedModule } from 'app/shared/shared.module';

// Translation
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
	declarations: [UserMenuComponent],
	imports: [
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		SharedModule,
		RouterModule,
		TranslocoModule,
	],
	exports: [UserMenuComponent],
})
export class UserMenuModule {}
