<ng-container *transloco="let t; read: 'user-menu'">
	<!-- Button -->
	<button mat-icon-button [matMenuTriggerFor]="userActions">
		<span class="relative">
			<img
				class="w-7 h-7 rounded-full"
				*ngIf="showAvatar && user.photoURL"
				[src]="user.photoURL"
			/>
			<mat-icon
				*ngIf="!showAvatar || !user.photoURL"
				[svgIcon]="'heroicons_outline:user-circle'"
			></mat-icon>
			<span
				*ngIf="user"
				class="absolute right-0 bottom-0 w-2 h-2 rounded-full"
				[ngClass]="{
					'mr-px mb-px': !showAvatar || !user.photoURL,
					'bg-green-500': user.status === 'online',
					'bg-amber-500': user.status === 'away',
					'bg-red-500': user.status === 'busy',
					'bg-gray-400': user.status === 'not-visible'
				}"
			></span>
		</span>
	</button>

	<mat-menu [xPosition]="'before'" #userActions="matMenu">
		<button mat-menu-item>
			<span class="flex flex-col leading-none">
				<span>{{ t("signed_in") }}</span>
				<span class="mt-1.5 text-md font-medium">{{ user ? user.email : "" }}</span>
			</span>
		</button>
		<mat-divider class="my-2"></mat-divider>
		<button mat-menu-item [routerLink]="'settings'">
			<mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
			<span>{{ t("settings") }}</span>
		</button>
		<!-- <button mat-menu-item [matMenuTriggerFor]="userStatus">
        <mat-icon
            [svgIcon]="'heroicons_outline:dots-circle-horizontal'"
        ></mat-icon>
        <span>Status</span>
    </button> -->
		<button mat-menu-item [matMenuTriggerFor]="schemeMenu">
			<mat-icon [svgIcon]="'heroicons_outline:color-swatch'"></mat-icon>
			<span>{{ t("design") }}</span>
		</button>
		<button mat-menu-item [matMenuTriggerFor]="languageMenu">
			<mat-icon [svgIcon]="'heroicons_outline:translate'"></mat-icon>
			<span>{{ t("language") }}</span>
		</button>
		<mat-divider *ngIf="isAdmin" class="my-2"></mat-divider>
		<button *ngIf="isAdmin" mat-menu-item (click)="switchToAdmin()">
			<mat-icon [svgIcon]="'heroicons_outline:code'"></mat-icon>
			<span>{{ t("admin") }}</span>
		</button>
		<mat-divider class="my-2"></mat-divider>
		<button mat-menu-item (click)="signOut()">
			<mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
			<span>{{ t("sign_out") }}</span>
		</button>
	</mat-menu>

	<!-- <mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu> -->

	<mat-menu class="user-status-menu" #schemeMenu="matMenu">
		<button mat-menu-item (click)="setScheme('dark')">
			<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:moon'"></mat-icon>
			<span>{{ t("design_choice.dark") }}</span>
		</button>
		<button mat-menu-item (click)="setScheme('light')">
			<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:sun'"></mat-icon>
			<span>{{ t("design_choice.light") }}</span>
		</button>
		<button mat-menu-item (click)="setScheme('auto')">
			<mat-icon
				class="icon-size-5"
				[svgIcon]="'heroicons_solid:refresh'"
			></mat-icon>
			<span>{{ t("design_choice.auto") }}</span>
		</button>
	</mat-menu>

	<mat-menu class="user-status-menu" #languageMenu="matMenu">
		<button mat-menu-item (click)="setLanguage('de')">
			<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:flag'"></mat-icon>
			<span>{{ t("lan_choice.de") }}</span>
		</button>
		<button mat-menu-item (click)="setLanguage('en')">
			<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:flag'"></mat-icon>
			<span>{{ t("lan_choice.en") }}</span>
		</button>
	</mat-menu>
</ng-container>
