import { Injectable } from '@angular/core';
// environment
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class UserBackendService {
	constructor(private _httpClient: HttpClient) {}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------
	linkAccount(accountId: string, userId: string, isAdmin?: string): Promise<void> {
		let url = environment.serviceURL + 'linkAccount';
		return new Promise<void>((resolve, reject) => {
			let data = JSON.stringify({ accountId: accountId, userId: userId, isAdmin: isAdmin });
			this._httpClient.post(url, data).subscribe({
				next: (res: any) => {
					resolve(res);
				},
				error: (error) => reject(error),
			});
		});
	}
}
