import { Component } from '@angular/core';

// translation
import { TranslocoService } from '@ngneat/transloco';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	/**
	 * Constructor
	 */
	constructor(private _translocoService: TranslocoService) {
		this._translocoService.setActiveLang(navigator.language.substr(0, 2));
	}
}
