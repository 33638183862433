import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Guide } from 'app/modules/help-center/help-center.type';
import { QuickhelpService } from 'app/layout/common/quickhelp/quickhelp.service';
import { Router } from '@angular/router';

@Component({
	selector: 'quickhelp',
	templateUrl: './quickhelp.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'quickhelp',
})
export class QuickhelpComponent implements OnInit, OnDestroy {
	guide: Guide;
	@ViewChild('quickhelpOrigin') private _quickhelpOrigin: MatButton;
	@ViewChild('quickhelpPanel') private _quickhelpPanel: TemplateRef<any>;

	mode: 'view' | 'modify' | 'add' | 'edit' = 'view';
	private _overlayRef: OverlayRef;
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private _quickhelpService: QuickhelpService,
		private _overlay: Overlay,
		private _router: Router,
		private _viewContainerRef: ViewContainerRef
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Get the quickhelp
		this._quickhelpService.guide$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((guide: Guide) => {
				// Load the quickhelp
				this.guide = guide;
				// Mark for check
				this._changeDetectorRef.markForCheck();
			});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();

		// Dispose the overlay
		if (this._overlayRef) {
			this._overlayRef.dispose();
		}
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Open the quickhelp panel
	 */
	openPanel(): void {
		// Return if the quickhelp panel or its origin is not defined
		if (!this._quickhelpPanel || !this._quickhelpOrigin) {
			return;
		}

		// Make sure to start in 'view' mode
		this.mode = 'view';

		// Create the overlay if it doesn't exist
		if (!this._overlayRef) {
			this._createOverlay();
		}

		// Attach the portal to the overlay
		this._overlayRef.attach(
			new TemplatePortal(this._quickhelpPanel, this._viewContainerRef)
		);
	}

	/**
	 * Close the messages panel
	 */
	closePanel(): void {
		this._overlayRef.detach();
	}

	/**
	 * Open the guide
	 */
	openGuide(): void {
		if (this.guide) {
			this._router.navigate([
				'help-center/guides',
				this.guide.categoryId,
				this.guide.id,
			]);
			this.closePanel();
		}
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Create the overlay
	 */
	private _createOverlay(): void {
		// Create the overlay
		this._overlayRef = this._overlay.create({
			hasBackdrop: true,
			backdropClass: 'fuse-backdrop-on-mobile',
			scrollStrategy: this._overlay.scrollStrategies.block(),
			positionStrategy: this._overlay
				.position()
				.flexibleConnectedTo(this._quickhelpOrigin._elementRef.nativeElement)
				.withLockedPosition()
				.withPush(true)
				.withPositions([
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top',
					},
					{
						originX: 'start',
						originY: 'top',
						overlayX: 'start',
						overlayY: 'bottom',
					},
					{
						originX: 'end',
						originY: 'bottom',
						overlayX: 'end',
						overlayY: 'top',
					},
					{
						originX: 'end',
						originY: 'top',
						overlayX: 'end',
						overlayY: 'bottom',
					},
				]),
		});

		// Detach the overlay from the portal on backdrop click
		this._overlayRef
			.backdropClick()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => {
				this._overlayRef.detach();
			});
	}
}
