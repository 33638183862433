import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { InitialData } from 'app/app.types';

// autogramm - models
import { User } from 'app/core/user/user.model';

//autogramm.io - services
import { UserService } from 'app/core/user/user.service';
import { AccountService } from 'app/core/account/account.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
	providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
	private _navigation;
	private _user: User;

	/**
	 * Constructor
	 */
	constructor(
		private _userService: UserService,
		private _accountService: AccountService,
		private _navigationService: NavigationService,
		private _authService: AuthService
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Use this resolver to resolve initial mock-api for the application
	 *
	 * @param route
	 * @param state
	 */
	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<any> {
		var data = {
			user: {},
			notifications: [],
			messages: [],
			navigation: {},
			account: {},
		};

		this._navigationService.menuStructure$
			.pipe(takeUntil(this._authService._unsubscribeAll))
			.subscribe((navigation) => {
				data.navigation = {
					compact: navigation.main,
					default: navigation.main,
					futuristic: navigation.main,
					horizontal: navigation.main,
				};
			});

		this._userService.user$
			.pipe(takeUntil(this._authService._unsubscribeAll))
			.subscribe((usr) => {
				data.user = usr;
			});

		this._accountService.account$
			.pipe(takeUntil(this._authService._unsubscribeAll))
			.subscribe((account) => {
				data.account = account;
			});

		return of(data);
	}
}
@Injectable({
    providedIn: "root",
})
export class AuthDataResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(private _authService: AuthService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<any> {
        return this._authService.initAuthData();
    }
}