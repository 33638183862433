import {
	ActivatedRouteSnapshot,
	Route,
	RouterStateSnapshot,
} from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver, AuthDataResolver } from 'app/app.resolvers';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

// Angular Fire AuthGuard
import {
	AngularFireAuthGuard,
	redirectUnauthorizedTo,
	redirectLoggedInTo,
	emailVerified,
	customClaims,
} from '@angular/fire/auth-guard';

// Pipes to redirect users
const redirectUnauthorizedToLogin = (
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => redirectUnauthorizedTo(`sign-in?redirectURL=${state.url}`);

const redirectToLogin = (
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => redirectUnauthorizedTo(`sign-in`);

const redirectLoggedInToDashboard = () => redirectLoggedInTo('dashboard');
const redirectUnverifiedUser = () =>
	pipe(
		emailVerified,
		map((emailVerified) => {
			if (emailVerified) {
				return true;
			} else {
				return ['confirmation-required'];
			}
		})
	);
const adminOnly = () =>
	pipe(
		customClaims,
		map((claims) => {
			if (claims.admin) {
				return true;
			} else {
				return ['dashboard'];
			}
		})
	);

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
	// Redirect empty path to '/dashboard'
	{ path: '', pathMatch: 'full', redirectTo: 'dashboard' },

	// Redirect signed in user to the '/dashboard'
	//
	// After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
	// path. Below is another redirection for that path to redirect the user to the desired
	// location. This is a small convenience to keep all main routes together here on this file.
	{ path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

	// Auth routes for unauthorized users
	{
		path: '',
		canActivate: [AngularFireAuthGuard],
		component: LayoutComponent,
		data: {
			layout: 'empty',
			authGuardPipe: redirectLoggedInToDashboard,
		},
		children: [
			{
				path: 'forgot-password',
				loadChildren: () =>
					import('app/modules/auth/forgot-password/forgot-password.module').then(
						(m) => m.AuthForgotPasswordModule
					),
			},
			{
				path: 'reset-password',
				loadChildren: () =>
					import('app/modules/auth/reset-password/reset-password.module').then(
						(m) => m.AuthResetPasswordModule
					),
			},
			{
				path: 'sign-in',
				loadChildren: () =>
					import('app/modules/auth/sign-in/sign-in.module').then(
						(m) => m.AuthSignInModule
					),
			},
			{
				path: 'sign-up',
				loadChildren: () =>
					import('app/modules/auth/sign-up/sign-up.module').then(
						(m) => m.AuthSignUpModule
					),
			},
			{
				path: 'invitation',
				loadChildren: () =>
					import('app/modules/auth/invitation/invitation.module').then(
						(m) => m.AuthInvitationModule
					),
			},
			{
				path: 'sign',
				loadChildren: () =>
					import('app/modules/sign/sign.module').then((m) => m.SignModule),
			},
		],
	},
	// Auth routes for unverified users
	{
		path: '',
		canActivate: [AngularFireAuthGuard],
		component: LayoutComponent,
		data: {
			layout: 'empty',
			authGuardPipe: redirectToLogin,
		},
		children: [
			{
				path: 'confirmation-required',
				loadChildren: () =>
					import(
						'app/modules/auth/confirmation-required/confirmation-required.module'
					).then((m) => m.AuthConfirmationRequiredModule),
			},
			{
				path: 'sign-out',
				loadChildren: () =>
					import('app/modules/auth/sign-out/sign-out.module').then(
						(m) => m.AuthSignOutModule
					),
			},
		],
	},
	// Auth routes for authenticated users
	{
		path: '',
		canActivate: [AngularFireAuthGuard],
		component: LayoutComponent,
		data: { authGuardPipe: redirectUnauthorizedToLogin },
		resolve: {
			initialData: InitialDataResolver,
			AuthDataResolver,
		},
		children: [
			{
				path: 'dashboard',
				loadChildren: () =>
					import('app/modules/dashboard/dashboard.module').then(
						(m) => m.DashboardModule
					),
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: redirectUnverifiedUser },
			},
			{
				path: 'documents',
				loadChildren: () =>
					import('app/modules/documents/documents.module').then(
						(m) => m.DocumentsModule
					),
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: redirectUnverifiedUser },
			},
			{
				path: 'file-manager',
				loadChildren: () =>
					import('app/modules/file-manager/file-manager.module').then(
						(m) => m.FileManagerModule
					),
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: redirectUnverifiedUser },
			},
			{
				path: 'settings',
				loadChildren: () =>
					import('app/modules/settings/settings.module').then(
						(m) => m.SettingsModule
					),
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: redirectUnverifiedUser },
			},
			{
				path: 'help-center',
				loadChildren: () =>
					import('app/modules/help-center/help-center.module').then(
						(m) => m.HelpCenterModule
					),
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: redirectUnverifiedUser },
			},
			{
				path: 'templates',
				loadChildren: () =>
					import('app/modules/templates/templates.module').then(
						(m) => m.TemplatesModule
					),
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: redirectUnverifiedUser },
			},
			{
				path: 'sign-request',
				loadChildren: () =>
					import('app/modules/sign-request/sign-request.module').then(
						(m) => m.SignRequestModule
					),
				canActivate: [AngularFireAuthGuard],
				data: { authGuardPipe: redirectUnverifiedUser },
			},
			{
				path: 'error',
				loadChildren: () =>
					import('app/modules/error/error.module').then((m) => m.ErrorModule),
			},
		],
	},
	// Admin routes
	{
		path: 'admin',
		loadChildren: () =>
			import('app/modules/admin/admin.module').then((m) => m.AdminModule),
		canActivate: [AngularFireAuthGuard],
		component: LayoutComponent,
		data: { authGuardPipe: adminOnly },
		resolve: {
			initialData: InitialDataResolver,
			AuthDataResolver,
		},
	},
	// redirect user to sign-in if user navigates to page that does not exist
	// authenticated users are automatically redirected to dashboard by the redirect for the sign-in
	{
		path: '**',
		redirectTo: 'sign-in',
		pathMatch: 'full',
	},
];
