export const environment = {
	production: false,
	hmr: false,
	canRegister: true,
	name: 'autogramm.io - DEV',
	serviceURL: 'https://europe-west1-autogramm-dev.cloudfunctions.net/',
	emulator: false,
	firebaseConfig: {
		apiKey: 'AIzaSyCjkWrmhq9I61DL90HaC8QjJi7Ldd_EMLc',
		authDomain: 'autogramm-dev.firebaseapp.com',
		projectId: 'autogramm-dev',
		storageBucket: 'autogramm-dev.appspot.com',
		messagingSenderId: '833150545655',
		appId: '1:833150545655:web:0234f1b53fd4bf22a22df2',
		measurementId: 'G-51SF767CPR',
	},
	versionConfig: {
		number: '2.0.0',
		name: 'Brave Barracuda',
	},
	stripe: {
		key: 'pk_test_51HezqhI3wcUmZe8NP2Tndz4UGnPZOjAdc5YArKrLRXhzVQMGudO14wFxkPILA48OfKIiJ2fOT6bPT2sU8Km36udK00kzb0qxKC',
	},
};
