import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Guide } from 'app/modules/help-center/help-center.type';
import { FirestoreFilter } from 'app/core/firebase/FirestoreFilter';
import { FirestoreService } from 'app/core/firebase/firestore.service';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

import { AuthService } from 'app/core/auth/auth.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class QuickhelpService {
	private _currentGuide: BehaviorSubject<Guide | null> =
		new BehaviorSubject<Guide>(null);

	/**
	 * Constructor
	 */
	constructor(
		private _firestoreService: FirestoreService,
		private _router: Router,
		private _translocoService: TranslocoService,
		private _authService: AuthService
	) {
		this._router.events
			.pipe(takeUntil(this._authService._unsubscribeAll))
			.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					this.getCurrentGuide();
				}
			});
		this._translocoService.langChanges$
			.pipe(takeUntil(this._authService._unsubscribeAll))
			.subscribe(() => {
				this.getCurrentGuide();
			});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for shortcuts
	 */
	get guide$(): Observable<Guide> {
		return this._currentGuide.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Gets the guide for the current opened url
	 * In case there is a dataset open, the id is removed
	 */
	async getCurrentGuide() {
		let currentUrl = this._router.url;
		if (currentUrl.split('/').length > 3)
			currentUrl = currentUrl.split('/').slice(0, -1).join('/');

		var queryFilter: FirestoreFilter = {
			column: 'view_link',
			value: currentUrl,
			operator: '==',
		};
		this._firestoreService
			.getCollectionQueryChanges('guides', queryFilter)
			.pipe(takeUntil(this._authService._unsubscribeAll))
			.subscribe((guides: Guide[]) => {
				const foundGuide =
					[...guides].find(
						(guide) => guide.language === this._translocoService.getActiveLang()
					) || null;
				this._currentGuide.next(foundGuide);
			});
	}
}
