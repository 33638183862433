import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseNavigationModule } from '@fuse/components/navigation';
import { MessagesModule } from 'app/layout/common/messages/messages.module';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { FeedbackModule } from 'app/layout/common/feedback/feedback.module';
import { UserMenuModule } from 'app/layout/common/user-menu/user-menu.module';
import { SharedModule } from 'app/shared/shared.module';
import { ClassyLayoutComponent } from 'app/layout/layouts/vertical/classy/classy.component';
import { QuickhelpModule} from 'app/layout/common/quickhelp/quickhelp.module';

@NgModule({
	declarations: [ClassyLayoutComponent],
	imports: [
		HttpClientModule,
		RouterModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		FuseNavigationModule,
		MessagesModule,
		NotificationsModule,
		SearchModule,
		ShortcutsModule,
		FeedbackModule,
		UserMenuModule,
		SharedModule,
		QuickhelpModule,
	],
	exports: [ClassyLayoutComponent],
})
export class ClassyLayoutModule {}
