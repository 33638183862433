import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';

//environment
import { environment } from 'environments/environment';

//Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { SETTINGS } from '@angular/fire/firestore';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslocoRootModule } from './transloco/transloco-root.module';

// autogramm Services
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { AccountService } from 'app/core/account/account.service';

// guard
import { SignGuard } from 'app/modules/sign/sign.guard';
import { NavigationService } from 'app/core/navigation/navigation.service';
import '@stripe/stripe-js';

import { USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/auth';
import { USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { USE_EMULATOR as FUNCTIONS_EMULATOR } from '@angular/fire/functions';
import { BUCKET as STORAGE_EMULATOR } from '@angular/fire/storage';

const routerConfig: ExtraOptions = {
	scrollPositionRestoration: 'enabled',
	preloadingStrategy: PreloadAllModules,
	relativeLinkResolution: 'legacy',
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(appRoutes, routerConfig),

		// Fuse & Fuse Mock API
		FuseModule,
		FuseConfigModule.forRoot(appConfig),
		// FuseMockApiModule.forRoot(mockApiServices),

		// Core
		CoreModule,

		// Layout
		LayoutModule,

		// 3rd party modules
		MarkdownModule.forRoot({}),

		// Firebase
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireStorageModule,

		// Translation
		HttpClientModule,
		TranslocoRootModule,
	],
	bootstrap: [AppComponent],
	providers: [
		// {
		// 	provide: AUTH_EMULATOR,
		// 	useValue: !environment.emulator ? undefined : ['localhost', 9099],
		// },
		// {
		// 	provide: STORAGE_EMULATOR,
		// 	useValue: !environment.emulator ? undefined : ['default-bucket'],
		// },
		{
			provide: SETTINGS,
			useValue: !environment.emulator
				? undefined
				: { host: 'localhost:8080', ssl: false },
		},
		// {
		// 	provide: FIRESTORE_EMULATOR,
		// 	useValue: !environment.emulator ? undefined : ['localhost', 8080],
		// },
		// {
		// 	provide: FUNCTIONS_EMULATOR,
		// 	useValue: !environment.emulator ? undefined : ['localhost', 5001],
		// },
		AuthService,
		UserService,
		AccountService,
		NavigationService,
	],
})
export class AppModule {}
